import { render, staticRenderFns } from "./StudentGamificationTemplate.vue?vue&type=template&id=929be364&scoped=true&"
import script from "./StudentGamificationTemplate.vue?vue&type=script&lang=js&"
export * from "./StudentGamificationTemplate.vue?vue&type=script&lang=js&"
import style0 from "./StudentGamificationTemplate.vue?vue&type=style&index=0&id=929be364&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "929be364",
  null
  
)

export default component.exports